import { useSpring, animated } from '@react-spring/web'
import ReactDOM                from 'react-dom'
import IconButton              from '../../layout/tools/iconButton/IconButton'
import ClearIcon               from '../../layout/icons/actions/ClearIcon'
import Backdrop                from '../../layout/tools/Backdrop'
import './modal.css'

function Modal({ isModalVisible, onBackdropClick, content, fullWidth, translateStart, translateEnd }) {

    const animation = useSpring({           
      opacity:   isModalVisible ? 1 : 0,
      transform: isModalVisible 
        ? translateStart 
          ? translateStart
          : 'translateX(0)' 
        : translateEnd 
          ? translateEnd
          : 'translateX(100%)'
    })    

    if(!isModalVisible) return null

    return ReactDOM.createPortal( 
      <>
      <Backdrop onclick={onBackdropClick} />

      <animated.div className={` ${fullWidth && 'fullWidth'} modal`} style={animation}>  

        <div  className="screenheight"> 

          {content}
          
        </div>

        {/* {!fullWidth && ( */}

          <IconButton
            tooltip="close"
            aria="close"
            icon={<ClearIcon />}
            onClick={onBackdropClick}
            style={{ zIndex: 2 }}
          />

        {/* )}  */}
        
      </animated.div>
      </>,
      document.getElementById('modal-root')    
    )
}

export default Modal
